<template>
    <div class="privacy-policy">
      <h2>Privacy Policy</h2>
      <p>We value your privacy and are committed to protecting your personal information. This Privacy Policy explains what information we collect from you, how we use that information, and your rights regarding your information.</p>
      <h3>1. Information We Collect</h3>
      <p>We collect information you provide directly to us, such as when you create an account, subscribe, participate in any interactive features of our services, fill out a form, or otherwise communicate with us.</p>
      <h3>2. How We Use Information</h3>
      <p>We use the information we collect to provide, maintain, and improve our services, such as to process transactions, send you related information, including purchase confirmations and invoices, provide customer support, and send you technical notices, updates, security alerts, and support and administrative messages.</p>
      <h3>3. Sharing of Information</h3>
      <p>We do not share your personal information with third parties except as described in this Privacy Policy. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</p>
      <h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  };
  </script>
  
  <style scoped>
  .privacy-policy {
    padding: 20px;
  }
  </style>
  