<template>
    <div class="terms-of-service">
      <h1>Terms of Service</h1>
      <p>Welcome to our website. By accessing and using this website, you accept and agree to be bound by the terms and conditions of this agreement. In addition, when using this website's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</p>
      <h3>1. Intellectual Property</h3>
      <p>The Site and its original content, features, and functionality are owned by our company and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
      <h3>2. Termination</h3>
      <p>We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that, by their nature, should survive termination, shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
      <h3>3. Changes To This Agreement</h3>
      <p>We reserve the right, at our sole discretion, to modify or replace these Terms by posting the updated terms on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms.</p>
      <h3>Contact Us</h3>
      <p>If you have any questions about this Agreement, please contact us.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "TermsOfService",
  };
  </script>
  
  <style scoped>
  .terms-of-service {
    padding: 20px;
  }
  </style>
  