<template>
  <div class="register-view">
    <div class="login-wrap">
      <div class="login-img"><img src="../assets/images/img-register.jpg"></div>
      <div class="login-con">
        <div class="login-box">
          <lay-form :model="registerModel" ref="registerForm" :rules="rules" name="userform">
            <div class="form-subtitle">Welcome to</div>
            <div class="form-title mb5"><a href="index.html">MyNewsScan</a></div>
            <lay-form-item class="form-item" prop="username">
              <label class="form-label">Username</label>
              <lay-input type="text" placeholder="5+ characters" v-model="registerModel.username" class="form-input icon-username">
                <template #prefix>
                  <font-awesome-icon icon="fa-regular fa-circle-user" style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
              </lay-input>
            </lay-form-item>
            <lay-form-item class="form-item m20" prop="email">
              <label class="form-label">Email</label>
              <lay-input type="text" v-model="registerModel.email" class="form-input icon-email">
                <template #prefix>
                  <font-awesome-icon icon="fa-regular fa-envelope" style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
              </lay-input>
            </lay-form-item>
            <lay-form-item class="form-item m20" prop="password">
              <label class="form-label">Password</label>
              <lay-input v-model="registerModel.password" :type="showPass1" placeholder="6-12 characters" class="form-input icon-password">
                <template #prefix>
                  <PasswordIcon style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
                <template #suffix>
                  <div v-show="filled1" class='show-hide' @click="showHide1">
                    <ShowIcon v-if="isShow1" style="font-weight: 900; font-size: 25px; color: #090970"/>
                    <HideIcon v-else style="font-weight: 900; font-size: 25px; color: #090970"/>
                  </div>
                </template>
              </lay-input>
            </lay-form-item>
            <lay-form-item class="form-item m20" prop="confirmPassword">
              <label class="form-label">Confirm Password</label>
              <lay-input v-model="registerModel.confirmPassword" :type="showPass2" placeholder="repeat password" class="form-input icon-password">
                <template #prefix>
                  <PasswordIcon style="font-weight: 900; font-size: 25px; color: #090970"/>
                </template>
                <template #suffix >
                  <div v-show="filled2" class='show-hide' @click="showHide2">
                    <ShowIcon v-if="isShow2" style="font-weight: 900; font-size: 25px; color: #090970"/>
                    <HideIcon v-else style="font-weight: 900; font-size: 25px; color: #090970"/>
                  </div>
                </template>
              </lay-input>
            </lay-form-item>
            <lay-form-item class="form-text mb5" prop="agreement">
              <label class="form-acc">
                <input type="checkbox" v-model="registerModel.agreement" class="form-checkbox">
                <span>
                  Creating an account means you’re okay with our 
                  <a href="javascript:void(0)" @click="openDialog('termsOfService')">Terms of Service</a>, 
                  <a href="javascript:void(0)" @click="openDialog('privacyPolicy')">Privacy Policy</a>, 
                  and our default 
                  <a href="javascript:void(0)" @click="openDialog('notificationSettings')">Notification Settings</a>.
                </span>
              </label>
            </lay-form-item>
            <lay-form-item class="form-item m20">
              <lay-button class="form-btn" @click="submit">Register</lay-button>
              <div class="form-tips">Already a member? <router-link to="/login">Sign in</router-link></div>
            </lay-form-item>
          </lay-form>
        </div>
        <div class="close-mark">
          <router-link to="/">
            <font-awesome-icon icon="xmark" style="font-size: 40px; font-weight: 50; position:absolute; right: 20px; top: 10px; color: #090970;"/>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Terms of Service Dialog -->
    <el-dialog v-model="dialogs.termsOfService" width="50%">
      <TermsOfService />
    </el-dialog>

    <!-- Privacy Policy Dialog -->
    <el-dialog v-model="dialogs.privacyPolicy" width="50%">
      <PrivacyPolicy />
    </el-dialog>

    <!-- Notification Settings Dialog -->
    <el-dialog v-model="dialogs.notificationSettings" width="50%">
      <NotificationSettings />
    </el-dialog>

  </div>
</template>

<script>
import { PasswordIcon, ShowIcon, HideIcon } from "@layui/icons-vue";
import { reactive, ref, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { post } from '@/api/index';
import { layer } from "@layui/layui-vue";
import { useRouter } from "vue-router";
import TermsOfService from "@/components/TermsOfService.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import NotificationSettings from "@/components/NotificationSettings.vue";

export default {
  name: "Register",
  components: {
    FontAwesomeIcon,
    PasswordIcon,
    ShowIcon,
    HideIcon,
    TermsOfService,
    PrivacyPolicy,
    NotificationSettings
  },
  setup() {
    const registerForm = ref();
    const registerModel = reactive({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      agreement: false
    });
    const router = useRouter();
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the username'));
      } else {
        if (value.length < 5) {
          callback(new Error('Please input no less than 5 characters'));
        } else {
          return true;
        }
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the email'));
      } else {
        const regEmail = /@/; 
        if (regEmail.test(value)) {
          callback();
        } else {
          callback(new Error('Please input an email with a legal format'));
        }
      }
    };

    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else {
        const regPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}/;
        if (regPassword.test(value)) {
          return true;
        } else {
          callback(new Error('Please input 6+ characters, MUST containing capital and lowercase letters, and number.'));
        }
      }
    };
    const validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the same password'));
      } else {
        if (value != registerModel.password) {
          callback(new Error('Not same'));
        } else {
          return true;
        }
      }
    };

    const validateAgreement = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('Please click and agree the terms above, before submitting'));
      } else {
        return true;
      }
    };

    const rules = reactive({
      username: { validator: validateName, trigger: 'blur' },
      email: { validator: validateEmail, trigger: 'blur' },
      password: { validator: validatePassword, trigger: 'blur' },
      confirmPassword: { validator: validatePassword2, trigger: 'blur' },
      agreement: { validator: validateAgreement, trigger: 'blur' }
    });
    const showPass1 = ref("password");
    const isShow1 = ref(true);
    const filled1 = ref(false);
    const showPass2 = ref("password");
    const isShow2 = ref(true);
    const filled2 = ref(false);
    const dialogs = reactive({
      termsOfService: false,
      privacyPolicy: false,
      notificationSettings: false
    });

    function showHide1() {
      let tag = !isShow1.value;
      if (tag == true) {
        showPass1.value = "password";
      } else {
        showPass1.value = "text";
      }
      isShow1.value = tag;
    }

    function showHide2() {
      let tag = !isShow2.value;
      if (tag == true) {
        showPass2.value = "password";
      } else {
        showPass2.value = "text";
      }
      isShow2.value = tag;
    }

    function submit() {
      registerForm.value.validate((isValidate) => {
        if (!isValidate) {
          return false;
        } else {
          post(`/register`, registerModel).then(res => {
            console.log(registerModel);
            if (res.res == 0) {
              layer.load(0, { time: 1500 });
              setTimeout(() => {
                layer.msg("Error, try again...", { icon: 2, time: 1500 });
              }, 1500);
            } else {
              layer.load(0, { time: 1500 });
              setTimeout(() => {
                layer.msg("Congrats, please login with your new account...", { icon: 1, time: 1500 });
                router.push("/login");
              }, 1500);
            }
          });
        }
      });
    }

    function openDialog(dialog) {
      dialogs[dialog] = true;
    }

    watch(() => registerModel.confirmPassword, (to) => {
      // console.log(to)
      if (to == "") {
        filled2.value = false;
      } else {
        filled2.value = true;
      }
    });

    watch(() => registerModel.password, (to) => {
      // console.log(to)
      if (to == "") {
        filled1.value = false;
      } else {
        filled1.value = true;
      }
    });

    return {
      registerForm,
      registerModel,
      showPass1,
      isShow1,
      showHide1,
      filled1,
      showPass2,
      isShow2,
      showHide2,
      filled2,
      rules,
      submit,
      dialogs,
      openDialog
    };
  }
};
</script>
 
<style scoped>
 .register-view{
   height: 100vh;
   background-color: #e5e5e5;
 }
 .login-wrap{
   position: absolute;
   left: 50%;
   top: 50%;
   transform:translate(-50%,-50%);
   display: flex;
   width: 1400px;
   min-height: 775px;
   padding: 0 0;
   overflow: hidden;
   border-radius: 15px;
   background-color: transparent;
 }
 .login-img{
   display: flex;
   flex: 0 0 527px;
   width: 527px;
   height: 775px;
   justify-content: center;
   align-items: center;
   background-color: #ccd7ed;
 }
 .login-img img{
   display: block;
   width: 100%;
   height: 100%;
 }
 .login-con{
   /*border: 1px solid;*/
   flex: 0 0 913px;
   width: 913px;
   padding-left: 170px;
   background-color: #FFFFFF;
   display: flex;
   align-items: center;
 }
 .login-box{
   display: block;
   width: 505px;
 }
 .form-subtitle{
   font-size: 32px;
 }
 .form-title{
   padding-top: 15px;
   font-size: 32px;
   color: #5473FF;
   padding-bottom: 40px;
   font-family: "Poppins-Black";
 }
 .form-title a{
   color: #5473FF;
 }
 .form-title a:hover{
   color: #2473FF;
 }
 .form-title.mb5{
   padding-bottom: 5px;
 }
 .form-item{
   display: block;
   margin-top: 28px;
   position: relative;
 }
 .form-item.m70{
   margin-top: 70px;
 }
 .form-item.m20{
   margin-top: 28px;
 }
 .form-label{
   display: block;
   font-size: 18px;
   font-family: "Poppins-Bold";
   margin-bottom: 10px;
 }
 .form-item.m20 .form-label{
   margin-bottom: 8px;
 }
 .form-input{
   width: 100%;
   height: 50px;
   font-size: 15px;
   padding: 0 16px;
   background-color: #F2F2F2;
   border: none;
   border-radius: 15px;
 }
 .form-input:hover,.form-input:focus{
   background-color: #EAEAEA;
 }
 .show-hide:hover{
   cursor: pointer;
 }
 .form-text{
   display: flex;
   padding: 10px 20px 20px 12px;
   font-size: 12px;
   line-height: 18px;
   margin-top: 10px;
   /*border: 1px solid;*/
   align-items: center;
   align-content: center;
   justify-content: space-between;
 }
 .form-text.mb5{
   padding-bottom: 2px;
 }
 .form-text .fl{
   font-family: "Poppins-Bold";
 }
 .form-checkbox{
   width: 16px;
   height: 16px;
   vertical-align: -3.5px;
   margin-right: 10px;
   background-color: #CACACA;
 }
 .form-btn{
   display: block;
   width: 180px;
   height: 50px;
   font-size: 20px;
   color: #FFF !important;
   line-height: 50px;
   text-align: center;
   border: none;
   margin: 0 auto;
   border-radius: 25px;
   cursor: pointer;
   font-family: "Poppins-Black";
   background-color: #5473FF;
   box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
 }
 .form-btn:hover{
   background-color: #4261f0;
   box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
 }
 .form-tips{
   display: block;
   width: 100%;
   margin-top: 18px;
   font-size: 12px;
   text-align: center;
 }
 .form-tips a{
   color: #3B33B3;
 }
 .form-tips a:hover{
   text-decoration: underline;
 }
 .form-acc{
   display: flex;
   font-size: 12px;
   color: #646464;
 }
 .form-checkbox{
   flex: 0 0 16px;
 }
 .form-acc a{
   color: #3B33B3;
 }
 .form-acc a:hover{
   text-decoration: underline;
 }
 </style>
