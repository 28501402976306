<template>
  <!-- Hasn't put under use -->
    <div class="notification-settings">
      <h2>Notification Settings</h2>
      <p>You can control what notifications you receive and how you receive them. Below are the settings available for managing your notifications.</p>
      <h3>1. Email Notifications</h3>
      <p>You can choose to receive email notifications for various activities such as new messages, updates, and offers. You can manage your email notification preferences in your account settings.</p>
      <h3>2. Push Notifications</h3>
      <p>Push notifications can be enabled or disabled for real-time alerts on your device. Manage these settings on your device's notification settings panel.</p>
      <h3>3. SMS Notifications</h3>
      <p>If you have opted in for SMS notifications, you will receive text messages for important updates and offers. You can opt out of SMS notifications at any time through your account settings or by following the instructions in the text message.</p>
      <h3>Contact Us</h3>
      <p>If you have any questions about these Notification Settings, please contact us.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotificationSettings",
  };
  </script>
  
  <style scoped>
  .notification-settings {
    padding: 20px;
  }
  </style>
  